const Desc = () => {
  return (
    <section>
      <div className="pt-[57.5px] max-lg:py-[45px] pb-[128px] max-xl:gap-[50px] max-sm:py-[24px] max-xl:justify-between max-2lg:gap-[20px] max-lg:flex-col max-lg:gap-[80px] max-sm:gap-[36px] flex gap-[216px]">
        <div className="flex flex-col gap-[80px] max-lg:gap-[45px] max-sm:gap-[36px]">
          <h1 className="w-[532px] max-sm:text-[24px] max-lg:w-full text-[36px] leading-[120%] letter-spacing-2">
            Leaf & Hive, known for pushing the boundaries of brewing, sought a
            digital presence that mirrored the sophistication of their Jun
            creations.
          </h1>
          <div className="flex flex-col gap-[6px] max-sm:gap-[4px]">
            <h1 className="text-[36px] widescreen:text-[40px] widestscreen:text-[50px] max-sm:text-[24px] max-lg:text-[36px] leading-[120%] letter-spacing-2">
                          #1 Honeybrew beverage
            </h1>
            <p className="text-base opacity-80 widescreen:text-[20px] widestscreen:text-[24px]">in Santa Fe</p>
          </div>
        </div>
        <div className="w-[440px] widescreen:w-[700px] widestscreen:w-[900px] max-lg:w-[100%] max-md:w-full flex flex-col gap-[63px] max-sm:gap-[40px]">
          <div className="w-[342px] max-lg:w-full flex flex-col gap-[30px]">
            <div className=" w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
              <div className="opacity-60 text-black widescreen:text-[16px] widestscreen:text-[20px] text-sm font-normal font-['Archivo'] uppercase leading-snug">
                Industry
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="max-sm:w-[150px] bg-[#F2F2F2] w-[176px] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
              Food & beverages
            </div>
          </div>
          <div className="flex flex-col gap-[30px]">
            <div className="w-[342px] widescreen:w-full max-lg:w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
              <div className="opacity-60 text-black widescreen:text-[16px] widestscreen:text-[20px] text-sm font-normal font-['Archivo'] uppercase leading-snug">
                services provided
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="flex gap-[14px] flex-wrap w-[100%]">
            <div className="bg-[#F2F2F2] px-[16px] max-sm:text-sm h-[40px] text-[#000000] rounded-[8px] flex justify-center items-center text-base font-normal font-['Archivo'] capitalize">
                User Experience
              </div>
            <div className="bg-[#F2F2F2] widescreen:text-[20px] widestscreen:text-[24px] widescreen:py-[12px] widestscreen:py-[16px] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                ecommerce development
              </div>
            <div className="bg-[#F2F2F2] widescreen:text-[20px] widestscreen:text-[24px] widescreen:py-[12px] widestscreen:py-[16px] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                product engineering
              </div>
            <div className="bg-[#F2F2F2] widescreen:text-[20px] widestscreen:text-[24px] widescreen:py-[12px] widestscreen:py-[16px] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                experience design
              </div>
            <div className="bg-[#F2F2F2] widescreen:text-[20px] widestscreen:text-[24px] widescreen:py-[12px] widestscreen:py-[16px] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                CMS development
              </div>
            <div className="bg-[#F2F2F2] widescreen:text-[20px] widestscreen:text-[24px] widescreen:py-[12px] widestscreen:py-[16px] py-[8px] px-[16px] text-[#000000] rounded-[8px] flex justify-center items-center text-base max-sm:text-sm font-normal font-['Archivo']">
                digital transformation
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[30px]">
            <div className="w-[342px] widescreen:w-full max-lg:w-full justify-start items-center gap-[13.42px] inline-flex max-sm:w-[100%]">
              <div className="opacity-60 text-black widescreen:text-[16px] widestscreen:text-[20px] text-sm font-normal font-['Archivo'] uppercase leading-snug">
                applications delivered
              </div>
              <div className="grow shrink basis-0 h-[0px] opacity-30 border border-black"></div>
            </div>
            <div className="flex gap-[14px] flex-wrap w-[100%]">
            <div className="bg-[#F2F2F2] px-[16px] max-sm:text-sm h-[40px] text-[#000000] rounded-[8px] flex justify-center items-center text-base font-normal font-['Archivo'] capitalize">
                Delivery Management
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Desc;
